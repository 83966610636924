import autoComplete from '@tarekraafat/autocomplete.js/';

jQuery(function($) {




    class FBAutocomplete {

        constructor(input) {

            this.input = input;
            this.form = input.parents('form');

            this.ac = new autoComplete({
                data: {
                    src: async () => {
                        // eg https://search.curtin.edu.au/s/suggest.json?collection=curtin-learning-offerings&partial_query=comm
                        const url = `https://search.curtin.edu.au/s/suggest.json?collection=${this.input.attr('data-collection')}&partial_query=${this.input.val()}`;
                        const source = await fetch(url);
                        return await source.json();
                    },
                    cache: false
                },
                selector: () => this.input[0],
                threshold: 2,
                debounce: 300,
                highlight: true,
                submit: true, // Responsible for the Enter button default behavior
                maxResults: 5,
                resultsList: {
                    position: "afterend",
                    element: (source, data) => {
                        source.removeAttribute("id", "autoComplete_list");
                        source.setAttribute("class", "autocomplete-list");
                    }
                },
                resultItem: {
                    element: (source, data) => {
                        source.innerHTML = data.match;
                    } 
                },
            });
            
            // click on suggestion item
            this.ac.input.addEventListener("selection", function (item) {
                const val = item.detail.selection.value;
                input.val(val);
                input.parents('form').trigger('submit');
            });

            // empty any autocompletes clicking elsewhere
            $(window).on('click', (e) => {
                $('.autoComplete_result').remove();
            });
        }

    }

    if ($('.fb-autocomplete').length) {

        $('.fb-autocomplete').each(function() {
            new FBAutocomplete($(this));
        });
    }

});