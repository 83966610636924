(function ($) {

    $.fn.ulSelect = function(callback){
        var $this = $(this);
      
        if (!$this.hasClass('kt-tab-to-select')) {
            $this.addClass('kt-tab-to-select');
        }

        
        $this.find('li:first-of-type').addClass('active');
        $this.on('click', 'li', function(){
          // Toggle active class on the <ul>
          $this.toggleClass('active');
          // Remove active class from any <li> that has it...
          $this.children().removeClass('active');
          // And add the class to the <li> that gets clicked
          $(this).toggleClass('active');
          // If the <ul> dropdown is open, activate the selected, and append the clicked <li> text (and SVG arrow)
          if ($this.hasClass('active')) {
            $(this).prependTo(".kt-tab-to-select");
          }
    
        });
          // Close dropdown when clicking outside it
          $(document).on('click', function(event){
            if(!$this.has(event.target).length == 0) {
              return;
            }
            else {
              $this.removeClass('active');
            }
        });
      }

    $(document).ready(function () {
          // apply dropdown layout
          if(('.kt-tabs-mobile-layout-accordion').length) {
            $('.kt-tabs-mobile-layout-accordion .kt-tabs-title-list').ulSelect();
          }
    });

})(jQuery);