import fancyBox from '@fancyapps/fancybox';
import niceSelect from 'jquery-nice-select';
import { hasForms2 } from "../helpers";

jQuery(function ($) {

    $('.forms-modal').each(function () {
        var form_id = $(this).data('form-id'),
            form_src = '#form-modal-' + form_id,
            form_trigger = '#forms-' + form_id;

        // Modal trigger
        $('a[href="' + form_trigger + '"]').fancybox({
            touch: false,
            src: form_src
        });

        if ( $(this).data('form-type') == 'marketo' && hasForms2() ){
            MktoForms2.loadForm("//app-sn03.marketo.com", "091-LWC-058", form_id);
        }

    });


    $('.ci-blanket__toggle').click(function () {

        // if using masthead slider, go to the first slide
        // resize will cause a slick refresh - buggy otherwise
        $('.masthead-slider__stage').slick('resize');

        $('main.page-container').toggle(0, function () {
            $('.ci-blanket').fadeToggle();
        });

    });

    $(document).ready(function () {

        if ($('.ci-blanket').length) {
            $('main.page-container').hide();
        }

    });


});

