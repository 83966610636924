import niceSelect from 'jquery-nice-select';

$(function() {

    // Forminator Form Select
    $('.forminator-field select[id^="address"]').niceSelect();

    // Style Guide
    $('.form-select').niceSelect();
});
