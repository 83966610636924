(function ($) {

    $(document).ready(function () {

        if (!$('#help').length) return false;

        $('.support__faq').on('click', (e) => {

            const faqID = $(e.target).attr('data-faq-id');

            if (faqID) {

                $.fancybox.open([
                    {
                        src:  `${siteData.ajaxURI}?action=get_support_faq&faq_id=${faqID}`,
                        type: 'ajax',
                        touch: false
                    }
                ]);

                return false;
            }

            return true;
        });
    });

})(jQuery);