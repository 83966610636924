import Fancybox from '@fancyapps/fancybox';
import { setCookie, getCookie, scrollToY } from "../helpers";

jQuery(function($) {

    class Personalisation {

        constructor() {
            const segment = getCookie('segment');

            let params = (new URL(document.location)).searchParams;
            let segmentFromURL = params.get('segment');

            // is there a valid ?segment query string? use that
            if (segmentFromURL && (segmentFromURL === 'int' || segmentFromURL === 'dom')) {
                setCookie('segment', segmentFromURL);
            } else if (!segment) {
                if ($('#personalisation-modal').length) {
                    if (!getCookie('has_seen_personalisation')) {
                        $.fancybox.open({
                            src: '#personalisation-modal',
                            autoFocus: false,
                            clickSlide: false,
                            smallBtn: false,
                            toolbar: false,
                            keyboard: false,
                            afterClose: (instance, slide) => {
                                // if user just closes the modal, set the segment cookie based on the AU_VISITOR cookie
                                if (getCookie('segment') === undefined) {
                                    setCookie('segment', this.isIntString());
                                }
                                setCookie('has_seen_personalisation', true);
                            }
                        });
                    }
                } else {
                    setCookie('segment', 'dom');
                }
            }

            $('[data-personalisation-modal]').fancybox({
                src: '#personalisation-modal',
                autoFocus: false,
                smallBtn: false,
                toolbar: false,
                clickSlide: false,
                keyboard: false,
            });

            this.showSegmentHandler();
        }

        setSegment(segment) {
            setCookie('segment', segment);
        }

        showSegmentHandler() {
            $(document).on('click', '[data-set-segment]', (e) => {

                const oldSegment = getCookie('segment') ? getCookie('segment') : this.isIntString();
                const newSegment = $(e.target).data('set-segment');

                $(e.target).addClass('active')
                    .siblings()
                    .removeClass('active');

                this.setSegment(newSegment);
                // only reload the page if user has changed segment, else just close the modal
                if (oldSegment !== newSegment) {
                    $('#personalisation-modal').addClass('crl-loading');
                    setCookie('has_seen_personalisation', true);
                    location.reload(); // if a new segment, reload all up in here
                } else {
                    setTimeout(() => {
                        $.fancybox.close();
                    }, 100);
                }
            });
        }

        isInt() {
            return getCookie('AU_VISITOR') === "0";
        }

        isIntString() {
            return this.isInt() ? 'int' : 'dom';
        }
    }

    new Personalisation();

});