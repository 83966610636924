function copyLink(element) {
    let link = element.data('link');

    if (link) {
        element.on('click', function(){
            console.log("Copying " + link);
            // Copy the text inside the text field
            navigator.clipboard.writeText(link)
                .then(() => {
                    $(this).children('.text').text('Copied!');

                    setTimeout(function () {
                        $(this).children('.text').text('Copy Link');
                    }, 5000);
                })
                .catch(() => {
                    console.log('Something went wrong');
                });
        });
    }
}

$(() => {
    let $copy = $('a.copy-link');

    ($copy.length && window.isSecureContext) ? copyLink($copy) : $copy.hide();
});