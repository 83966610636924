(function ($) {

    function accordionAriaToggle($this){
        // toggle aria controls
        var state = $this.hasClass('active');

        $this.find('.accordion__heading')
            .attr('aria-expanded', state)
            .next('.accordion__content')
            .attr('aria-hidden', !state);

    }

    function accordionInit() {
        // TODO - duplicate code fragment, remove this or the one below
        // check if an item has been requested via url
        let hash = window.location.hash ?? false;
        if(hash) {
            let $target = $('.accordion__toggle[aria-controls=' + hash.substring(1) + ']').parent('.accordion');// remove the hash, find the parent
            $target.addClass('active');

            // scroll to the accordion
            $([document.documentElement, document.body]).animate({
                scrollTop: ($target.offset().top - 110)// offset for sticky header
            }, 1000);
        }


        // open active accordions
        $('.accordion.active').find('.accordion__content').show();
        accordionAriaToggle($('.accordion.active'));

    }

    // leave this outside accordionInit() for injected accordions to available on .ready
    $(document).on('click', '.accordion__toggle', function() {

        var $this = $(this),
            accordion = $this.parent('.accordion');

        // toggle accordion content
        accordion.toggleClass('active')
            .trigger('accordionToggle') // see table-responsive.js for handling of this event
            .find('.accordion__content')
            .slideToggle();

        accordionAriaToggle(accordion);

    });

    $(document).ready(function () {
        if ($('.accordion').length) {
            accordionInit();

            // check if an item has been requested via url
            let hash = window.location.hash ?? false;
            if(hash) {
                let $target = $('.accordion__toggle[aria-controls=' + hash.substring(1) + ']').parent('.accordion');// remove the hash, find the parent
                $target.addClass('active');
                accordionAriaToggle($target);

                // scroll to the accordion
                $([document.documentElement, document.body]).animate({
                    scrollTop: ($target.offset().top - 110)// offset for sticky header
                }, 1000);
            }

        }
    });

})(jQuery);
