import fancyBox from '@fancyapps/fancybox';

(function($) {

    function imageSliderGallery() {
        $('.image-slider').each(function() {

            // using an id allows multiple instances on a page
            let fancyId = ($(this).find('.image-slider__stage').attr('id')).replace('slick-', '');

            $('[data-fancybox="image-slider-gallery__' + fancyId + '"]').fancybox({
                arrows: true,
                buttons: [
                    'slideShow',
                    'share',
                    'zoom',
                    'fullScreen',
                    'close'
                ],
                image: {
                    preload: true
                },
                loop: true,
                thumbs: {
                    autoStart: true
                },
                slideShow: {
                    autoStart: false,
                    speed: 3000
                },
            });
        });
    }

    function fancyWPImageBlock() {
        // lets use fancybox for the WP image block,
        // add a block class name, to group images
        let fancy_images = [];
        $('#page-content .wp-block-image').each(function() {
            // for each image block, gather common class names, so we can group images.
            // assume last class is user defined - in block settings
            let classes = (($(this).attr('class')).split(' ')).pop();

            // no duplicates
            if(!fancy_images.includes(classes)) {
                fancy_images.push(classes);
            }

            // set fancy box attr's
            $(this).find('> a, > img').attr('data-fancybox', classes).attr('data-fancy-wp-img', '');
            // data-src for images not wrapped in <a>
            $(this).find('> img').attr('data-src', $(this).find('> img').attr('src'));
        });

        // set custom options
        $('[data-fancy-wp-img]').fancybox({
            baseClass: "fancy-wp-img",// for the stylez
            slideClass: "fancy-wp-img__slide",
            loop: true,
            toolbar: true,
            thumbs: {
                autoStart: true,// display thumbnails on opening
            },
        });
    }

    $(document).ready(function() {

        if($('.image-slider').length) {
            imageSliderGallery();
        }

        if($('#page-content .wp-block-image').length) {
            fancyWPImageBlock();
        }

        $('.footer__socials a[aria-label="WeChat"]').fancybox({
            src: '#wechat-qr'
        });

        // open modal based on # parameter
        // works for both normal and form modals
        // e.g. http://example.com/#my-modal
        // e.g. http://example.com/#form-modal-3355
        // check for = char as it will throw a syntax error
        let $modal_id = !(document.location.hash).includes('=') ? document.location.hash : false;

        if($modal_id === '#wechat-qr' || $modal_id && $('.modal' + $modal_id).length) {
            $.fancybox.open({
                src: $modal_id
            });
        }
    });


    /*
    * Modal defaults
    *
    * */
    {
        $.fancybox.defaults.animationDuration = "200";

        // default modal display to inline-flex - this helps with overflow working correctly/better
        $.fancybox.defaults.afterLoad = function() {
            $('.fancybox-is-open .modal').css('display', 'inline-flex');
        };

        // close button
        $.fancybox.defaults.btnTpl.smallBtn =
            '<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small button--ico" title="{{CLOSE}}">' +
            '<svg class="modal-close-btn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M25.71 7.71l-1.42-1.42-8.29 8.3-8.29-8.3-1.42 1.42 8.3 8.29-8.3 8.29 1.42 1.42 8.29-8.3 8.29 8.3 1.42-1.42-8.3-8.29 8.3-8.29z"/></svg>' +
            "</button>"
        ;

        $.fancybox.defaults.btnTpl.close =
            '<button type="button" data-fancybox-close class="fancybox-button fancybox-button--close button--ico" title="{{CLOSE}}">' +
            '<svg class="modal-close-btn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M25.71 7.71l-1.42-1.42-8.29 8.3-8.29-8.3-1.42 1.42 8.3 8.29-8.3 8.29 1.42 1.42 8.29-8.3 8.29 8.3 1.42-1.42-8.3-8.29 8.3-8.29z"/></svg>' +
            "</button>"
        ;

        // Modal trigger via href
        $('a[href*=":modal="]').each(function() {
            var $this = $(this);
            // split the link ref and grab the part after :modal=
            var modal_link = $this.attr('href').split(':modal=')[1];
            // remove link href and add the req modal attributes
            $this.removeAttr('href', '')
                .attr('data-src', '#' + modal_link)
                .attr('data-fancybox', '');
        });
    }


})(jQuery);
