
jQuery(function($) {
    // breadcrumbs is based on breadcrumbs NavXT plugin
    let breadcrumbs = $('.breadcrumbs__container');
    let breadcrumbsItems = $('.breadcrumbs__container').find('[property="itemListElement" ]').length;

    function breadcrumbsHandler() { 
        let breadcrumbWidth = breadcrumbs.outerWidth();
        let windowWidth = $(window).width();
        let gap = windowWidth - breadcrumbWidth;

        // When we have more than 3 breadcrumb items and the empty space is less than 500px
        if( breadcrumbsItems >= 3 && gap < 500 ) {
            let detachedChildren = breadcrumbs.children().slice(2,-2).detach();

            let collapseDot = jQuery('<span property="itemListElement" typeof="ListItem"><a property="item" typeof="WebPage" title="Show all the breadcrumbs" href=""><span property="name">...</span></a></span>');

            breadcrumbs.find('.ico').first().after(collapseDot);

            collapseDot.on('click', function (event) {
                event.preventDefault();
                breadcrumbs.children().slice(2, -2).remove();
                breadcrumbs.children('.ico').first().after(detachedChildren);
            })
        }
        
    }

    $(document).ready(function() {
        if(breadcrumbs.length) {
            breadcrumbsHandler();
        }
    });

})